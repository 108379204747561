.react-datepicker-wrapper {
    display: unset !important;
  }
  @media screen and (max-width: 767px) {
  .doctor-datepicker{
    margin-right: 60px !important;
  }
  
  .patient-datepicker {
    margin-left: 60px !important;
  }
}
