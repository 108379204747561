.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointment-grid-weekview-table tr:nth-child(odd) td:first-child {
  @apply border-b-0; /* Remove bottom border */
}

.appointment-grid-weekview-table tr:nth-child(odd) td:not(:first-child) {
  @apply border-b border-solid;
}

.appointment-grid-weekview-table tr:nth-child(even) {
  @apply border-b border-solid;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit */
}

/* Hide scrollbar in Firefox */
.scrollbar-hide {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}